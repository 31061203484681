.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Name {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  align-items: center;
  font-family: "NameFont";
  font-size: 40px;
  margin-top: -100px;
}

.Name2 {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  font-family: "NameFont";
  font-size: 20px;
}

.fade-in {
  transition: opacity 3s ease;
  opacity: 1;
}

.fade-in2 {
  opacity: 0;
  animation-name: fadeInAnimation;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.shadow {
  text-shadow: 1px 0px 0px grey;
}

.nav-border {
  border-bottom-width: 1px;
  border-top-width: 1px;
}

.border-1 {
  border-width: 1px;
}

.App-link {
  color: #61dafb;
}

.min-h-custom {
  min-height: 90vh;
}

.min-h-home {
  min-height: 500px;
}

.text-xxs {
  font-size: 0.5rem;
  line-height: 0.75rem;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}