body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "NameFont";   /*Can be any text*/
  src: local("muli.light"),
    url("./fonts/muli.light.ttf") format("truetype");
}

@font-face {
  font-family: "MuliRegular";   /*Can be any text*/
  src: local("muli.regular"),
    url("./fonts/muli.regular.ttf") format("truetype");
}

@font-face {
  font-family: "Code";   /*Can be any text*/
  src: local("Code"),
    url("./fonts/Courier\ Prime.ttf") format("truetype");
}

@font-face {
  font-family: "Code2";   /*Can be any text*/
  src: local("Code2"),
    url("./fonts/SourceCodePro-Regular.ttf") format("truetype");
}





@tailwind base;
@tailwind components;
@tailwind utilities;
